@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.item {
  box-sizing: border-box !important;
  display: flex !important;
  flex-shrink: 0 !important;
  align-items: center !important;
  height: fit-content !important;
  min-height: 112px !important;
  padding: 12px !important;
  gap: 30px !important;
  background: $white-200 !important;
  border-radius: $border-radius-l !important;
  box-shadow: 0 0 24px 0 #29278c12 !important;

  @include mq($to: md) {
    flex-direction: column !important;
    width: 312px !important;
    padding: 16px !important;
  }

  &__date_block {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    max-width: 140px !important;
    height: 80px !important;
    border: 1px solid #e8ebf1 !important;
    border-radius: $border-radius-s !important;
    background: $white-100 !important;

    @include mq($to: md) {
      width: 100% !important;
      max-width: unset !important;
    }

    &__title {
      font-size: 18px !important;
    }
  }

  &__button {
    flex-shrink: 0 !important;
    margin: 0 0 0 auto !important;
    padding: 14px !important;
    background: $white-300 !important;

    &:hover {
      & svg {
        color: $white-100 !important;
      }
    }

    @include mq($to: md) {
      align-self: flex-start !important;
      margin: 0 !important;
    }

    > span {
      display: flex !important;
      gap: 12px !important;
    }
  }

  &__descr_block {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;

    @include mq($to: md) {
      width: 100% !important;
    }

    &__label {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 108px !important;
      height: 26px !important;
      border-radius: $border-radius-2xl !important;
      background: $grad-purple-horizontal !important;
    }

    &__title {
      line-height: 1 !important;

      &_container {
        display: flex !important;
        gap: 8px !important;

        @include mq($to: md) {
          flex-direction: column-reverse !important;
          gap: 12px !important;
        }

        // align-items: center;
      }
    }
  }
}
