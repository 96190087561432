@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.section {
  padding: 40px 0 !important;

  @include mq($to: sm) {
    padding: 20px 0 !important;
  }

  &__list {
    display: grid !important;
    margin: 60px 0 0 !important;
    padding: 0 !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 60px !important;

    @include mq($to: lg) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @include mq($to: sm) {
      grid-template-columns: 1fr !important;
      gap: 20px !important;
      margin-top: 30px !important;
    }

    &_item {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      max-width: 423px !important;
      padding: 20px 0 50px !important;
      gap: 5px !important;
      border-top: 2px solid $light-gray-100 !important;

      @include mq($to: sm) {
        padding: 20px 0 !important;
      }

      &__title {
        font-family: $font-family--headings !important;
        font-size: $font-size-3xl !important;
        font-weight: $font-weight-l !important;
        line-height: $line-height-xs !important;
        letter-spacing: -0.03em !important;
        color: $green-500 !important;
        margin: 0 !important;

        @include mq($to: sm) {
          font-size: $font-size-mob-l !important;
          line-height: $line-height-s !important;
        }
      }
    }
  }

  &__title {
    margin-bottom: 16px !important;
  }
}
