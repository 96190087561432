@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
/* stylelint-disable media-feature-range-notation */
.section {
  position: relative !important;
  margin: 40px 0 !important;
  padding: 100px 685px 100px 100px !important;
  border-radius: 16px !important;
  background: linear-gradient(90deg, $green-400 0%, $green-300 100%) !important;

  @include mq($to: xxl) {
    padding: 60px 685px 60px 60px !important;
  }

  @media only screen and (max-width: 1280px) {
    padding: 60px 60px 550px !important;
  }

  @include mq($to: md) {
    margin: 20px 0 !important;
    padding: 26px 26px 550px !important;
  }

  @include mq($to: sm) {
    margin: 20px 0 !important;
    padding: 26px 26px 400px !important;
  }

  &__container {
    max-width: 1760px !important;
    overflow: hidden !important;
  }

  &__title {
    margin-bottom: 20px !important;
  }

  &__content {
    max-width: 974px !important;
  }

  &__button {
    margin-top: 40px !important;

    @include mq($to: sm) {
      justify-content: center !important;
      width: 100% !important;
    }
  }

  &__pizza_img {
    position: absolute !important;
    top: 50% !important;
    right: 177.6px !important;
    transform: translateY(-50%) !important;

    @media only screen and (max-width: 1280px) {
      top: unset !important;
      bottom: 91.18px !important;
      left: 50% !important;
      width: 340px !important;
      height: 340px !important;
      transform: translateX(-50%) !important;
    }

    @include mq($to: md) {
      width: 260px !important;
      height: 260px !important;
    }

    @include mq($to: sm) {
      width: 219px !important;
      height: 219px !important;
    }
  }

  &__cola_img {
    position: absolute !important;
    top: 131.19px !important;
    right: -30px !important;

    @include mq($to: xxl) {
      right: -80px !important;
    }

    @media only screen and (max-width: 1280px) {
      top: unset !important;
      right: -40px !important;
      bottom: 238px !important;
    }

    @include mq($to: sm) {
      width: 117px !important;
      height: 117px !important;
    }
  }

  &__popcorn_img {
    position: absolute !important;
    right: 79.59px !important;
    bottom: 14.4px !important;
    transform: rotate(-20.246deg) !important;

    @media only screen and (max-width: 1280px) {
      right: 33.71px !important;
      bottom: 31.56px !important;
    }

    @include mq($to: sm) {
      width: 72px !important;
      height: 72px !important;
    }
  }

  &__fries_img {
    position: absolute !important;
    right: 489.4px !important;
    bottom: 0 !important;

    @include mq($to: xxl) {
      bottom: -25px !important;
      transform: rotate(-15deg) !important;
    }

    @media only screen and (max-width: 1280px) {
      right: unset !important;
      bottom: 0 !important;
      left: 26px !important;
    }

    @include mq($to: sm) {
      bottom: -15px !important;
      width: 74px !important;
      height: 74px !important;
    }
  }

  &__chicken_img {
    position: absolute !important;
    top: 105.19px !important;
    right: 546.67px !important;

    @media only screen and (max-width: 1280px) {
      top: unset !important;
      right: unset !important;
      bottom: 251px !important;
      left: 0 !important;
    }

    @include mq($to: sm) {
      width: 86px !important;
      height: 85px !important;
    }
  }

  &__hotdog_img {
    position: absolute !important;
    top: 0 !important;
    right: 275px !important;

    @include mq($to: xxl) {
      top: -45px !important;
    }

    @media only screen and (max-width: 1280px) {
      top: unset !important;
      right: unset !important;

      // bottom: 321px;
      bottom: 391px !important;
      left: 50% !important;
    }

    @include mq($to: sm) {
      bottom: 322px !important;
      width: 76px !important;
      height: 76px !important;
    }
  }

  &__grilled_chicken_img {
    position: absolute !important;
    right: 313.27px !important;
    bottom: 54px !important;
    transform: rotate(-30deg) !important;

    @media only screen and (max-width: 1280px) {
      right: unset !important;

      // bottom: 84.72px;
      bottom: 35.72px !important;
      left: 50% !important;
    }

    @include mq($to: sm) {
      bottom: 84.72px !important;
      width: 39px !important;
      height: 39px !important;
    }
  }
}
