@mixin container($max-width: $max-width, $page-gutter-default: $page-gutter--md) {
  @include push--auto;

  position: relative;
  width: 100%;
  max-width: $max-width;
  padding-left: $page-gutter-default;
  padding-right: $page-gutter-default;

  @include mq($to: 1380px) {
    padding-left: $page-gutter--sm;
    padding-right: $page-gutter--sm;
  }

  @include mq($to: xl) {
    padding-left: $page-gutter--sm;
    padding-right: $page-gutter--sm;
  }

  @include mq($to: lg) {
    padding-left: $page-gutter;
    padding-right: $page-gutter;
  }

  @include mq($to: md) {
    padding-left: $page-gutter--xs;
    padding-right: $page-gutter--xs;
  }
}
