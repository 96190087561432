@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.card {
  text-decoration: none !important;

  &__image_container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    aspect-ratio: 1 / 1 !important;
    background: $white-300 !important;
    border-radius: $border-radius-2xl !important;

    & img {
      width: 67.5% !important;
      aspect-ratio: 1 / 1 !important;
    }
  }

  &__title {
    margin: 14px 0 3px !important;
  }
}
