@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.section {
  padding: 60px 0 !important;

  @include mq($to: sm) {
    padding: 30px 0 !important;
  }

  &__header {
    margin-bottom: 60px !important;

    @include mq($to: md) {
      margin-bottom: 30px !important;
    }

    &__text_block {
      display: flex !important;
      justify-content: space-between !important;
      gap: 32px !important;
      width: 100% !important;

      @include mq($to: md) {
        flex-direction: column !important;
        gap: 20px !important;
      }
    }
  }

  &__title {
    max-width: 830px !important;
    margin-bottom: 16px !important;

    @include mq($to: md) {
      padding: 0 10px !important;
    }
  }

  &__descr {
    max-width: 830px !important;

    @include mq($to: md) {
      padding: 0 10px !important;
    }
  }

  &__button {
    flex-shrink: 0 !important;
    width: fit-content !important;

    @include mq($to: md) {
      justify-content: center !important;
      width: 100% !important;
    }
  }

  &__list {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
    gap: 20px 35px !important;
    margin: 0 !important;
    padding: 0 !important;

    @include mq($to: xxl) {
      grid-template-columns: repeat(5, 1fr) !important;
      gap: 20px 30px !important;
    }

    @include mq($to: xl) {
      grid-template-columns: repeat(4, 1fr) !important;
      gap: 20px 35px !important;
    }

    @include mq($to: lg) {
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 20px 30px !important;
    }

    @include mq($to: md) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @include mq($to: sm) {
      display: none !important;
    }
  }
}

.swiper {
  padding-left: 26px !important;
  display: none !important;

  @include mq($to: sm) {
    display: block !important;
  }
}
