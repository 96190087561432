@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.container {
  $block-name: &;

  @include container;

  &--size-infinity {
    @include container(none, 40px);
  }

  &--size-2xs {
    @include container(1080px);
  }

  &--size-3xs {
    @include container(980px);
  }

  &--size-xs {
    // @include container(1200px);
  }

  &--size-s {
    @include container(1300px);
  }

  &--size-s-up {
    @include container(1340px);
  }

  &--size-s-2up {
    @include container(1440px);
  }

  &--size-m {
    @include container;
  }

  &--size-l {
    @include container(1700px);
  }

  &--size-xl {
    @include container(1820px);
  }

  &--size-2xl {
    @include container(1900px);
  }

  &--size-3xl {
    @include container(2060px);
  }

  &--size-without-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none !important;
  }
}
