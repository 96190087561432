@import '~bourbon/core/bourbon';

@import 'mixins/mq';
@import 'mixins/on-event';
@import 'mixins/responsive-ratio';
@import 'mixins/css-triangle';
@import 'mixins/content-margins';
@import 'mixins/fp';
@import 'mixins/container';
@import 'mixins/align';
@import 'mixins/pos';
@import 'mixins/pseudo';
@import 'mixins/when-inside';
@import 'mixins/reset-mixins';
@import 'mixins/form-mixins';
@import 'mixins/visibility-mixins';
@import 'mixins/grid-mixins';
@import 'mixins/typo-mixins';
@import 'mixins/custom-scroll';
@import 'mixins/layout-flex-fix';
@import 'mixins/stretch-to-full-height';
@import 'mixins/skeleton';
@import 'mixins/ellipsis';

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin push--auto {
  margin: {
    right: auto;
    left: auto;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin flush--ends {
  margin: {
    top: 0;
    bottom: 0;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin flush--sides {
  margin: {
    right: 0;
    left: 0;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin hard--ends {
  padding: {
    top: 0;
    bottom: 0;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin hard--sides {
  padding: {
    right: 0;
    left: 0;
  }
}
