@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.tabs {
  & button.tabs__list_item__button {
    height: 51px !important;
    padding: 12px 24px !important;
    color: #2b1a94 !important;
    border-radius: $border-radius-2xl !important;
    font-size: 18px !important;
    font-weight: 500 !important;

    &:hover {
      color: $white-100 !important;
    }
  }

  & button.tabs__list_item__button--active {
    background-color: #2b1a94 !important;
    border-color: #2b1a94 !important;
    color: $white-100 !important;
    pointer-events: none !important;
  }

  &__list {
    display: flex !important;
    flex-flow: row wrap !important;
    margin: 40px 0 0 !important;
    padding: 0 !important;
    gap: 14px !important;
    list-style-type: none !important;
  }

  &__content_list {
    display: grid !important;
    padding: 0 !important;
    gap: 25px !important;
    grid-template-columns: repeat(3, 1fr) !important;

    @include mq($to: xxl) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @include mq($to: md) {
      grid-template-columns: 1fr !important;
      gap: 19px !important;
    }
  }
}

.card {
  display: flex !important;
  flex-direction: column !important;

  &__image {
    background-color: $white-400 !important;
    border-radius: $border-radius-s !important;
    width: 100% !important;

    // max-height: 288px;
    object-fit: cover !important;
    aspect-ratio: 1 / 0.587 !important;
  }

  &__title {
    padding: 11px 0 !important;
  }

  &_explore {
    list-style-type: none !important;

    &__content {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      border: 2px dashed #dce0e7 !important;
      object-fit: cover !important;
      aspect-ratio: 1 / 0.587 !important;
      border-radius: $border-radius-s !important;
      text-align: center !important;
      gap: 22px !important;
    }
  }
}
