@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.section {
  width: 100% !important;
  max-width: 1560px !important;
  margin: 0 auto !important;
  padding: 80px 0 60px !important;
  overflow: hidden !important;

  @include mq($to: md) {
    padding: 20px 0 !important;
  }

  &__container {
    @include mq($to: md) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__content {
    position: relative !important;
    padding: 80px 70px 70px !important;
    background-color: $white-300 !important;
    border-radius: $border-radius-2xl !important;

    @include mq($to: lg) {
      padding: 60px 36px 36px !important;
    }

    @include mq($to: md) {
      padding: 0 0 0 16px !important;
      background-color: unset !important;
    }

    &__pattern {
      position: absolute !important;
      top: 0 !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      aspect-ratio: 1/ 0.0736 !important;

      @include mq($to: xxl) {
        width: 85% !important;
      }

      @include mq($to: md) {
        display: none !important;
      }
    }
  }

  &__text_content {
    display: flex !important;
    padding: 15px 0 !important;
    gap: 57px !important;

    @include mq($to: md) {
      padding: 0 10px !important;
    }

    & img {
      flex-shrink: 0 !important;
      width: 149px !important;
      height: 149px !important;

      @include mq($to: md) {
        display: none !important;
      }
    }

    &__text {
      display: flex !important;
      flex-direction: column !important;
      gap: 16px !important;
      height: fit-content !important;

      @include mq($to: md) {
        padding-right: 36px !important;
      }
    }
  }

  &__list {
    display: flex !important;
    flex-direction: column !important;
    margin: 40px 0 0 !important;
    padding: 0 !important;
    gap: 15px !important;

    @include mq($to: md) {
      display: none !important;

      // flex-direction: row;
      // margin: 20px 0 0;
      // overflow-x: scroll;
      // gap: 11px;
    }
  }
}

.swiper {
  margin-top: 20px !important;
  display: none !important;
  width: 100% !important;
  overflow: visible !important;

  @include mq($to: md) {
    display: block !important;
  }
}

.slide {
  width: auto !important;
}
